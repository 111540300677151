/* @import url("https://rsms.me/inter/inter.css");
* {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  * {
    font-family: "Inter var", sans-serif;
  }
}

@import url("https://fonts.googleapis.com/css?family=Merriweather:700|Roboto+Mono:500&display=swap"); */

/* @import url("./Assets/Fonts/TiemposHeadline-Medium.otf"); */

@font-face {
  font-family: TiemposHeadline;
  src: url("./Assets/Fonts/TiemposHeadline-Medium.otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
