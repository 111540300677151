* {
  margin: 0;
  box-sizing: border-box;
}

p {
  /* font-size: 15px; */
  color: #222;
}

h5 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 12px;
  color: #999;
}

.link {
  display: inline-block;
  position: relative;
  text-decoration: none;
  padding-bottom: 1px;
  color: #f0c3ff;
  cursor: pointer;
  /* color: #66c3cc; */
  font-weight: 500;
}

.button-content > img {
  margin-left: 12px;
  transition: transform 200ms ease-out;
}
a:hover img {
  transform: translateX(8px);
}

.link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 0.15s ease-out;
}

.link:hover::after {
  transform: scaleX(1);
}

.link-wrapper {
  position: relative;
}

.link::after {
  background-color: #f0c3ff;
}

/*********/

.email-button:hover {
  color: #bf0017;
}
.linkedin-button:hover {
  color: #2f74ae;
}
.github-button:hover {
  color: #000;
}
.resume-button:hover {
  color: #0035a7;
}

a.email-wrapper {
  height: 32px;
  width: 32px;
  background-color: grey;
  transition: background 200ms ease-out;
}

a.email-wrapper:hover {
  background-color: black;
  transform: translateX(0px);
}

a.linkedin-wrapper:hover {
  background-color: black;
  transform: translateX(0px);
}

img.email {
  height: 24px;
  width: 24px;
  margin-left: 1px;
  margin-top: 4px;
  opacity: 1;
}

img.linked-in {
  height: 36px;
  width: 36px;
  margin-top: -2px;
  margin-left: -2px;
}

/*********/

.circles {
  text-align: center;
  position: absolute;
}

.first-circle {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0035a7;

  opacity: 0.3;

  animation-name: pulse;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.second-circle {
  margin-top: -24px;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #4686f4;
  opacity: 0;

  animation-name: pulse;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 2s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  25% {
    transform: scale(1.5);
    opacity: 0;
    animation-timing-function: ease-out;
  }
  50% {
    transform: scale(0.7);
    opacity: 0;
  }
  75% {
    transform: scale(0.5);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
}

/****/
a.mapboxgl-ctrl-logo {
  display: none !important;
}

div.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
